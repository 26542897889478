import React, { useEffect, useMemo, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import NbciUsInvoiceKeys from 'src/constants/locale/key/NbciUsInvoice'
import { Grid, Card, CardContent, Divider, Snackbar, SnackbarContent, Typography, IconButton,Box,InputAdornment } from '@material-ui/core'
import { components, DataFlattener, DateTimeFormatter, useTranslation, useServices, constants } from 'cng-web-lib'
import { useFormContext,useWatch } from "react-hook-form";
import { Close as CloseIcon } from '@material-ui/icons'
import makeValidationSchema from './MakeValidationSchema'
import NaUSPortAutocompleteField from '../../../components/na/autocomplete/codemaintenance/NaUSPortAutocompleteField'
import NaUSPortOfExitAutocompleteField from '../../../components/na/autocomplete/codemaintenance/NaUSPortOfExitAutocompleteField'
import { fetchUser } from 'src/views/userprofile/UserProfileService.js'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import TradePartySection from './TradePartySection'
import CiFormSection from './CiFormSection'
import AdditionalFormInformationSection from './AdditionalFormInformationSection'
import _ from 'lodash'
import moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import NbciUSInvoiceAutoCompleteMasterField from './NbciUSInvoiceAutoCompleteMasterField'
import NbciUsInvoiceApiUrls from 'src/apiUrls/NbciUsInvoiceApiUrls'

const {
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngCodeMasterAutocompleteField,
      CngUomAutocompleteField,
      CngCheckboxField
    }
  },
  CngGridItem
} = components

const {
  filter: { EQUAL }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: '',
  templateNameDropDown: '',
  invoiceNumber: '',
  invoiceNumber2: '',
  upsScsClientId: "",
  scacCode: '',
  invoiceDate: moment().tz("Canada/Eastern").format('YYYY-MM-DD'),
  carrierName: '',
  purchaseOrderNumber: '',
  shipmentControlNumber: '',
  currency: '',
  foreignPortOfExit: '',
  totalShipmentQuantity: '',
  portofEntry: '',
  totalShipmentQuantityUOM: '',
  transportationMode: 'M',
  totalItemsGrossWeight: '',
  totalItemsGrossWeightUom: '',
  entryNumber: 'Not available',
  totalItemsNetWeight: '',
  totalItemsNetWeightUom: '',
  invHeaderstatus: '',
  sno: '',
  exportCarrier: '',
  status: '',
  description: '',
  templateFlag: false,
  templateName: '',
  tradeParty: [],
  invoiceDetails: [],
  invoiceSummary: [],
  laceyAct: [],
  packingList: [],
  tscaform: [],
  bolForm: [],
  fdabtaform: [],
  usAPHISForm: [],
  naftaForm: [],
  ciForm: []
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap,onSetLoading }) {
  const { getValues, setValue, watch,trigger } = useFormContext()
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const currency = watch('currency')
  const transportationMode = watch('transportationMode')
  const status = watch('status')
  const tradeParty = watch('tradeParty')
  const cargoSummary = watch('ciForm.nbciusinvoiceCargoSummary')
  const ciForm = watch('ciForm')
  const templateFlag = watch('templateFlag')
  const portofEntry = watch('portofEntry')
  const foreignPortOfExit = watch('foreignPortOfExit')
  const totalItemsGrossWeightUom = watch('totalItemsGrossWeightUom')
  const totalItemsNetWeightUom = watch('totalItemsNetWeightUom')
  const totalShipmentQuantityUOM = watch('totalShipmentQuantityUOM')
  const renderCiForm = watch('renderCiForm')
  const invHeaderstatus = watch('invHeaderstatus')

  function makeTranslatedTextsObject() {
    const invoiceNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.INVOICE_NUMBER
    )
    const templateNameDropDown = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TEMPLATE_NAME
    )
    const scacCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.SCAC_CODE
    )
    const invoiceDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.INVOICE_DATE
    )
    const carrierName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CARRIER_NAME
    )
    const purchaseOrderNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.PURCHASE_ORDER_NUMBER
    )
    const shipmentControlNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.SHIPMENT_CONTROL_NUMBER
    )
    const currency = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CURRENCY
    )
    const foreignPortOfExit = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FOREIGN_PORT_OF_EXIT
    )
    const totalShipmentQuantity = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TOTAL_SHIPMENT_QUANTITY
    )
    const portofEntry = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.PORTOF_ENTRY
    )
    const totalShipmentQuantityUOM = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TOTAL_SHIPMENT_QUANTITY_U_O_M
    )
    const transportationMode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TRANSPORTATION_MODE
    )
    const totalItemsGrossWeight = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TOTAL_ITEMS_GROSS_WEIGHT
    )
    const totalItemsGrossWeightUom = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TOTAL_ITEMS_GROSS_WEIGHT_UOM
    )
    const entryNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.ENTRY_NUMBER
    )
    const totalItemsNetWeight = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TOTAL_ITEMS_NET_WEIGHT
    )
    const totalItemsNetWeightUom = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TOTAL_ITEMS_NET_WEIGHT_UOM
    )
    const exportCarrier = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.EXPORT_CARRIER
    )
    const status = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.STATUS
    )
    const cargoSummary = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceCargoSummary.TITLE
    )
    const description = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.DESCRIPTION
    )

    const templateFlag = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TEMPLATE_FLAG
    )
    const templateName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TEMPLATE_NAME
    )

    return {
      invoiceNumber,
      templateNameDropDown,
      invoiceDate,
      carrierName,
      purchaseOrderNumber,
      exportCarrier,
      scacCode,
      shipmentControlNumber,
      currency,
      foreignPortOfExit,
      portofEntry,
      transportationMode,
      totalShipmentQuantity,
      totalItemsGrossWeight,
      totalItemsGrossWeightUom,
      totalItemsNetWeight,
      totalItemsNetWeightUom,
      totalShipmentQuantityUOM,
      entryNumber,
      status,
      cargoSummary,
      description,
      templateFlag,
      templateName
    }
  }

  function handleApplyTemplate(template) {
    if (!template) return

    const { data } = template

    const {
      id
    } = data

    let rowData = {
      id: id
    }
    onSetLoading(true)
    securedSendRequest.execute(
      'POST',
      NbciUsInvoiceApiUrls.CLONE,
      rowData,
      (response) => {
        var data = response.data
        var errorMessages = data.errorMessages

        if (response.status == 200 && data != null && errorMessages == null) {
          let record = response.data
          setValue('transportationMode', record.transportationMode || '')
          setValue('scacCode', record.scacCode || '')
          setValue('carrierName', record.carrierName || '')
          setValue('purchaseOrderNumber', record.purchaseOrderNumber || '')
          setValue('shipmentControlNumber', record.shipmentControlNumber || '')
          setValue('currency', record.currency || '')
          setValue('foreignPortOfExit', record.foreignPortOfExit || '')
          setValue('totalShipmentQuantity', record.totalShipmentQuantity || '')
          setValue('portofEntry', record.portofEntry || '')
          setValue('totalShipmentQuantityUOM', record.totalShipmentQuantityUOM || '')
          setValue('invoiceDate', record.invoiceDate ? DateTimeFormatter.toClientDate(record.invoiceDate) : '')
          setValue('totalItemsGrossWeightUom', record.totalItemsGrossWeightUom || '')
          setValue('totalItemsGrossWeight', record.totalItemsGrossWeight || '')
          setValue('entryNumber', record.entryNumber || '')
          setValue('description', record.description || '')
          setValue('totalItemsNetWeight', record.totalItemsNetWeight || '')
          setValue('totalItemsNetWeightUom', record.totalItemsNetWeightUom || '')
          setValue('status', record.status || '')
          setValue('exportCarrier', record.exportCarrier || '')

          record.tradeParty.forEach((datum, index) => {
            datum['_id'] = index
          });

          setValue('tradeParty', record.tradeParty || [])
          setValue('ciForm', record.ciForm || null)
          setValue('invoiceSummary', record.invoiceSummary || null)
          setValue('laceyAct', record.laceyAct || null)
          setValue('packingList', record.packingList || null)
          setValue('invoiceDetails', record.invoiceDetails || [])
          setValue('tscaform', record.tscaform ||null)
          setValue('bolForm', record.bolForm || null)
          setValue('fdabtaform', record.fdabtaform || null)
          setValue('usAPHISForm', record.usAPHISForm || null)
          setValue('naftaForm', record.naftaForm || null)
         // setValue('ciForm.markPackLoad', record.ciForm.markPackLoad || '')
        
          setValue('renderCiForm','true')
        
          trigger()
        } else {
          showNotification('error', errorMessages)
        }
        onSetLoading(false)
      },
      (error) => console.error(error)
    )
   
  }

  const { securedSendRequest } = useServices();
  const [user, setUser] = useState([]);
  const [openDialog, setOpenDialog] = useState(true)
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest, fileForUserDetails);
  }, []);
  function onClose() {
    setOpenDialog(false)
  }
  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  const [clientIdSet, isClientIdSet] = useState(true);
  useEffect(() => {
    let clientIdExists = false;
    if (user && Object.keys(user).length > 0) {
      console.log("upsScsClientId"+ JSON.stringify(user.upsSacClientId));
      if (user.upsSacClientId) {
        user.upsSacClientId.forEach((e) => {
          console.log("upsScsClientId inside for each"+ JSON.stringify(e.upsSacClientId));
          var upsClID=e.upsSacClientId;
          if(upsClID){
              if(upsClID.indexOf("~") !== -1){
                if(upsClID.substring(0,upsClID.indexOf("~")).length>0){
                  upsClID= upsClID.substring(0,upsClID.indexOf("~"));
                }else{
                  upsClID= upsClID.substring(upsClID.indexOf("~")+1,upsClID.length);
                }
              }
              upsClID=upsClID.trim();
            }
          setValue('upsScsClientId', upsClID, { shouldValidate: true });
          clientIdExists = true;
        })
      }
      isClientIdSet(clientIdExists);
    }

  }, [user])

  useEffect(() => {
    var upsClID="";
    if (user && Object.keys(user).length > 0) {
      console.log("upsScsClientId an"+ JSON.stringify(user.upsSacClientId));
      if (user.upsSacClientId) {
         user.upsSacClientId.forEach((e) => {
          //setValue('upsScsClientId', e.upsSacClientId, { shouldValidate: true });
          upsClID=e.upsSacClientId;
        })
      }
      if(upsClID){
        tradeParty.forEach((e)=>{
        if(e.partyType=="LW"){
          if(upsClID.indexOf("~") !== -1){
            if(upsClID.substring(0,upsClID.indexOf("~")).length>0){
              upsClID= upsClID.substring(0,upsClID.indexOf("~"));
            }else{
              upsClID= upsClID.substring(upsClID.indexOf("~")+1,upsClID.length);
            }
          }
          console.log("upsClID"+ JSON.stringify(upsClID));
          e.partyIdentifierNumber=upsClID.trim();
          e.partyIdentifierCode='UPSC';
        }
        setValue("tradeParty",tradeParty);
      })
    }
  }
  }, [user,tradeParty])

  const memoedTradePartySection = useMemo(
    () => <TradePartySection tradeParty={tradeParty} />,
    [tradeParty]
  )

  const memoedCiFormSection = useMemo(
    () => (
      <CiFormSection invHeaderstatus={invHeaderstatus}
      />
    ),
    []
  )

  return (
    <Grid container spacing={3}>
      <Snackbar open={!clientIdSet && openDialog} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <SnackbarContent message={
          <>
            <Grid>
              <Typography variant="h4">UPS SCS Client Id is not yet configured.
                  <IconButton 
                    size='small' 
                    onClick={onClose}
                    style={{ position: 'absolute', top: 0, right: 0 }}>
                    <CloseIcon style={{ color: 'white' }} />
                  </IconButton>
              </Typography>

              <Divider style={{ marginTop: '5px', marginBottom: '10px' }} />

              <Typography variant="h5">Please navigate to Shared &gt; User Settings and configure the UPS SCS Client Id under the
              <b> General </b>
              section.</Typography>
            </Grid>
          </>
        } />
      </Snackbar>
      <Grid item xs={12}>
        <Card variant='outlined'>
          <Box padding={1}>
            <Grid alignItems='stretch' container spacing={1}>
              <Grid item xs={12} md={4}>
                <NbciUSInvoiceAutoCompleteMasterField
                  name='templateNameDropDown'
                  label='Auto-fill using template'
                  disabled={disabled}
                  onChange={(_, options) => handleApplyTemplate(options)}
                  size='small'
                  fullWidth
                  disableClearable
                  textFieldProps={{
                    InputProps: {
                      customEndAdornment: () => (
                        <InputAdornment position='end' style={{ marginTop: -16 }}>
                          <Box display='flex' alignItems='center' justifyContent='center' width={48} height={48}>
                            <FontAwesomeIcon icon={['fal', 'money-check-edit']} />
                          </Box>
                        </InputAdornment>
                      )
                    }
                  }}
                  forcePopupIcon={false}
                  lookupProps={{
                    filters: [{
                      field: 'partyId',
                      operator: EQUAL,
                      value: FileForUserGetPartyId()
                    }]
                  }}
                />
              </Grid>
              <Grid item xs='auto'>
                <Divider orientation='vertical' />
              </Grid>
              <Grid item xs={12} md>
                <Grid alignItems='center' container spacing={1}>
                  <CngGridItem xs={12} sm='auto' shouldHide={shouldHideMap?.templateFlag}>
                    <CngCheckboxField
                      label={
                        <Typography style={{ fontSize: 12, lineHeight: 1.2 }}>
                          {translatedTextsObject.templateFlag}
                        </Typography>
                      }
                      name='templateFlag'
                      disabled={disabled}
                      onChange={(event) =>
                        setValue('templateFlag', event.target.checked)
                      }
                      size='small'
                      style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                      value={!templateFlag ? false : true}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm shouldHide={shouldHideMap?.templateName}>
                    <CngTextField
                      name='templateName'
                      label={translatedTextsObject.templateName}
                      disabled={!templateFlag}
                      size='small'
                    />
                  </CngGridItem>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: 600 }}>
                  Invoice information
                </Typography>
                <Typography color='textSecondary' variant='caption'>
                  <Typography variant='inherit' color='error'>
                    *
                  </Typography>
                  Mandatory fields
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.invoiceNumber}>
                    <CngTextField
                      name='invoiceNumber'
                      inputProps={{ maxLength: 35 }}
                      label={translatedTextsObject.invoiceNumber}
                      disabled={disabled}
                      isRequired
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.invoiceDate}>
                    <CngDateField
                      name='invoiceDate'
                      label={translatedTextsObject.invoiceDate}
                      disabled={disabled}
                      isRequired
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.carrierName}>
                    <CngTextField
                      name='carrierName'
                      inputProps={{ maxLength: 30 }}
                      label={translatedTextsObject.carrierName}
                      disabled={disabled}
                      isRequired
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.purchaseOrderNumber}>
                    <CngTextField
                      name='purchaseOrderNumber'
                      inputProps={{ maxLength: 22 }}
                      label={translatedTextsObject.purchaseOrderNumber}
                      disabled={disabled}
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.exportCarrier}>
                    <CngTextField
                      name='exportCarrier'
                      inputProps={{ maxLength: 30 }}
                      label={translatedTextsObject.exportCarrier}
                      disabled={disabled}
                      isRequired
                      size='small'
                    />
                  </CngGridItem>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={1}>
                      <CngGridItem xs={6} lg={4} shouldHide={shouldHideMap.scacCode}>
                        <CngTextField
                          name='scacCode'
                          inputProps={{ maxLength: 4 }}
                          label={translatedTextsObject.scacCode}
                          disabled={disabled}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={6} lg={8} shouldHide={shouldHideMap.shipmentControlNumber}>
                        <CngTextField
                          name='shipmentControlNumber'
                          inputProps={{ maxLength: 12 }}
                          label={translatedTextsObject.shipmentControlNumber}
                          disabled={disabled}
                          size='small'
                        />
                      </CngGridItem>
                    </Grid>
                  </Grid>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.currency}>
                    <CngCodeMasterAutocompleteField
                      name='currency'
                      key={currency}
                      label={translatedTextsObject.currency}
                      disabled={disabled}
                      isRequired
                      size='small'
                      codeType='NBCI_INV_CUR'
                      lookupProps={{
                        label: (record) => `${record.code};${record.descriptionEn}`
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.foreignPortOfExit}>
                    <NaUSPortOfExitAutocompleteField
                      name='foreignPortOfExit'
                      key={foreignPortOfExit}
                      label={translatedTextsObject.foreignPortOfExit}
                      disabled={disabled}
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.portofEntry}>
                    <NaUSPortAutocompleteField
                      name='portofEntry'
                      key={portofEntry}
                      label={translatedTextsObject.portofEntry}
                      disabled={disabled}
                      isRequired
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.transportationMode}>
                    <CngCodeMasterAutocompleteField
                      name='transportationMode'
                      key={transportationMode}
                      label={translatedTextsObject.transportationMode}
                      disabled={disabled}
                      isRequired
                      size='small'
                      codeType='US_INV_TRANSPORT_MODE'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.totalShipmentQuantity}>
                    <CngTextField
                      name='totalShipmentQuantity'
                      inputProps={{ maxLength: 12 }}
                      label={translatedTextsObject.totalShipmentQuantity}
                      disabled={disabled}
                      isRequired
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.totalItemsGrossWeight}>
                    <CngTextField
                      name='totalItemsGrossWeight'
                      inputProps={{ maxLength: 9 }}
                      label={translatedTextsObject.totalItemsGrossWeight}
                      disabled={disabled}
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.totalItemsGrossWeightUom}>
                    <CngCodeMasterAutocompleteField
                      name='totalItemsGrossWeightUom'
                      key={totalItemsGrossWeightUom}
                      inputProps={{ maxLength: 9 }}
                      label={translatedTextsObject.totalItemsGrossWeightUom}
                      disabled={disabled}
                      size='small'
                      codeType='US_INV_WEIGHT_UOM'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.totalItemsNetWeight}>
                    <CngTextField
                      name='totalItemsNetWeight'
                      label={translatedTextsObject.totalItemsNetWeight}
                      disabled={disabled}
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.totalItemsNetWeightUom}>
                    <CngCodeMasterAutocompleteField
                      name='totalItemsNetWeightUom'
                      key={totalItemsNetWeightUom}
                      label={translatedTextsObject.totalItemsNetWeightUom}
                      disabled={disabled}
                      size='small'
                      codeType='US_INV_WEIGHT_UOM'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.totalShipmentQuantityUOM}>
                    <CngUomAutocompleteField
                      name='totalShipmentQuantityUOM'
                      key={totalShipmentQuantityUOM}
                      inputProps={{ maxLength: 2 }}
                      label={translatedTextsObject.totalShipmentQuantityUOM}
                      disabled={disabled}
                      isRequired
                      size='small'
                      lookupProps={{
                        label: (record) => `${record.code}; ${record.descriptionEn}`,
                        filters: [
                          { field: 'uomType', operator: EQUAL, value: 'UPS' }
                        ]
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.entryNumber}>
                    <CngTextField
                      name='entryNumber'
                      inputProps={{ maxLength: 30 }}
                      label={translatedTextsObject.entryNumber}
                      disabled={disabled}
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.status}>
                    <CngCodeMasterAutocompleteField
                      name='status'
                      key={status}
                      label={translatedTextsObject.status}
                      disabled={disabled}
                      codeType='US_INV_STATUS'
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.description}>
                    <CngTextField
                      name='description'
                      inputProps={{ maxLength: 30 }}
                      label={translatedTextsObject.description}
                      disabled={disabled}
                      isRequired
                      size='small'
                    />
                  </CngGridItem>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        {memoedTradePartySection}
      </Grid>
      <Grid item xs={12}>
        {memoedCiFormSection}
      </Grid>
      <Grid item xs={12}>
        <AdditionalFormInformationSection />
      </Grid>
    </Grid>
  )
}

function toClientDataFormat(serverData) {
  let localData = { ...serverData }

  localData.invoiceDetails.forEach((invoiceDetail, index) => {
    invoiceDetail['_id'] = index
    invoiceDetail['dateRangeFrom'] = DateTimeFormatter.toClientDate(invoiceDetail.dateRangeFrom)
    invoiceDetail['dateRangeTo'] = DateTimeFormatter.toClientDate(invoiceDetail.dateRangeTo)

    invoiceDetail.nbciusinvoiceDetailsFDAForm.forEach((fdaForm, index) => {
      fdaForm['_id'] = index
    })
  })

  return localData
}

function toServerDataFormat(localData) {
  let serverData = { ...localData }
  serverData.invoiceDetails.forEach((invoiceDetail) => {
    invoiceDetail.nbciusinvoiceDetailsEPA35201Form.forEach((nbciusinvoiceDetailsEPA35201Forms) => {
      if (nbciusinvoiceDetailsEPA35201Forms) {
        if (nbciusinvoiceDetailsEPA35201Forms.vehicleTypeA == true) {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeA = 'A'
        } else {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeA = ''
        }
        if (nbciusinvoiceDetailsEPA35201Forms.vehicleTypeB == true) {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeB = 'B'
        } else {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeB = ''
        }
        if (nbciusinvoiceDetailsEPA35201Forms.vehicleTypeF == true) {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeF = 'F'
        } else {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeF = ''
        }
        if (nbciusinvoiceDetailsEPA35201Forms.vehicleTypeEE == true) {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeEE = 'EE'
        } else {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeEE = ''
        }
        if (nbciusinvoiceDetailsEPA35201Forms.vehicleTypeFF == true) {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeFF = 'FF'
        } else {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeFF = ''
        }
        if (nbciusinvoiceDetailsEPA35201Forms.vehicleTypeM == true) {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeM = 'M'
        } else {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeM = ''
        }
        if (nbciusinvoiceDetailsEPA35201Forms.vehicleTypeE == true) {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeE = 'E'
        } else {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeE = ''
        }
        if (nbciusinvoiceDetailsEPA35201Forms.vehicleTypeL == true) {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeL = 'L'
        } else {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeL = ''
        }
        if (nbciusinvoiceDetailsEPA35201Forms.vehicleTypeU == true) {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeU = 'U'
        } else {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeU = ''
        }
        if (nbciusinvoiceDetailsEPA35201Forms.vehicleTypeW == true) {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeW = 'W'
        } else {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeW = ''
        }
        if (nbciusinvoiceDetailsEPA35201Forms.vehicleTypeY == true) {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeY = 'Y'
        } else {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeY = ''
        }
        if (nbciusinvoiceDetailsEPA35201Forms.vehicleTypeI == true) {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeI = 'I'
        } else {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeI = ''
        }
        if (nbciusinvoiceDetailsEPA35201Forms.vehicleTypeG == true) {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeG = 'G'
        } else {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeG = ''
        }
        if (nbciusinvoiceDetailsEPA35201Forms.vehicleTypeK == true) {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeK = 'K'
        } else {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeK = ''
        }
        if (nbciusinvoiceDetailsEPA35201Forms.vehicleTypeN == true) {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeN = 'N'
        } else {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeN = ''
        }
        if (nbciusinvoiceDetailsEPA35201Forms.vehicleTypeO == true) {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeO = 'O'
        } else {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeO = ''
        }
        if (nbciusinvoiceDetailsEPA35201Forms.vehicleTypeC == true) {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeC = 'C'
        } else {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeC = ''
        }
        if (nbciusinvoiceDetailsEPA35201Forms.vehicleTypeJ == true) {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeJ = 'J'
        } else {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeJ = ''
        }
        if (nbciusinvoiceDetailsEPA35201Forms.vehicleTypeZ == true) {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeZ = 'Z'
        } else {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeZ = ''
        }
        if (nbciusinvoiceDetailsEPA35201Forms.vehicleTypeH == true) {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeH = 'H'
        } else {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeH = ''
        }
        if (nbciusinvoiceDetailsEPA35201Forms.vehicleTypeQ == true) {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeQ = 'Q'
        } else {
          nbciusinvoiceDetailsEPA35201Forms.vehicleTypeQ = ''
        }
      }
    })
    invoiceDetail.nbciusinvoiceDetailsEPA352021Form.forEach((nbciusinvoiceDetailsEPA352021Forms) => {
      if (nbciusinvoiceDetailsEPA352021Forms) {
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentTypeA == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentTypeA = 'A'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentTypeA = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentTypeB == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentTypeB = 'B'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentTypeB = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentTypeC == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentTypeC = 'C'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentTypeC = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentTypeD == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentTypeD = 'D'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentTypeD = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentTypeE == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentTypeE = 'E'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentTypeE = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentTypeF == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentTypeF = 'F'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentTypeF = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentTypeG == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentTypeG = 'G'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentTypeG = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentTypeH == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentTypeH = 'H'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentTypeH = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType1 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType1 = '1'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType1 = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType2 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType2 = '2'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType2 = ''
        }

        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType3 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType3 = '3'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType3 = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType4 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType4 = '4'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType4 = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType5 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType5 = '5'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType5 = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType6 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType6 = '6'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType6 = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType7 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType7 = '7'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType7 = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType8 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType8 = '8'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType8 = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType9 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType9 = '9'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType9 = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType10 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType10 = '10'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType10 = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType11 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType11 = '11'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType11 = ''
        }

        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType12 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType12 = '12'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType12 = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType13 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType13 = '13'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType13 = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType14 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType14 = '14'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType14 = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType15 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType15 = '15'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType15 = ''
        }

        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType16 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType16 = '16'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType16 = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType17 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType17 = '17'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType17 = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType18 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType18 = '18'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType18 = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType19 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType19 = '19'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType19 = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType20 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType20 = '20'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType20 = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType21 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType21 = '21'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType21 = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType22 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType22 = '22'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType22 = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType23 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType23 = '23'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType23 = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType24 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType24 = '24'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType24 = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType25 == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType25 = '25'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType25 = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType26A == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType26A = '26A'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType26A = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType26B == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType26B = '26B'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType26B = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType26C == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType26C = '26C'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType26C = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType24Power == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType24Power = 'HP'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType24Power = ''
        }
        if (nbciusinvoiceDetailsEPA352021Forms.equipmentType24Watts == true) {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType24Watts = 'KW'
        } else {
          nbciusinvoiceDetailsEPA352021Forms.equipmentType24Watts = ''
        }
      }
    })
    invoiceDetail.nbciusinvoiceDetailsDOTForm.forEach((nbciusinvoiceDetailsDOTForms) => {
      if (nbciusinvoiceDetailsDOTForms) {
        if (nbciusinvoiceDetailsDOTForms.safety1 == true) {
          nbciusinvoiceDetailsDOTForms.safety1 = '1'
        } else {
          nbciusinvoiceDetailsDOTForms.safety1 = ''
        }
        if (nbciusinvoiceDetailsDOTForms.safety2A == true) {
          nbciusinvoiceDetailsDOTForms.safety2A = '2A'
        } else {
          nbciusinvoiceDetailsDOTForms.safety2A = ''
        }
        if (nbciusinvoiceDetailsDOTForms.safety2B == true) {
          nbciusinvoiceDetailsDOTForms.safety2B = '2B'
        } else {
          nbciusinvoiceDetailsDOTForms.safety2B = ''
        }
        if (nbciusinvoiceDetailsDOTForms.safety3 == true) {
          nbciusinvoiceDetailsDOTForms.safety3 = '3'
        } else {
          nbciusinvoiceDetailsDOTForms.safety3 = ''
        }
        if (nbciusinvoiceDetailsDOTForms.safety4 == true) {
          nbciusinvoiceDetailsDOTForms.safety4 = '4'
        } else {
          nbciusinvoiceDetailsDOTForms.safety4 = ''
        }
        if (nbciusinvoiceDetailsDOTForms.safety5 == true) {
          nbciusinvoiceDetailsDOTForms.safety5 = '5'
        } else {
          nbciusinvoiceDetailsDOTForms.safety5 = ''
        }
        if (nbciusinvoiceDetailsDOTForms.safety6 == true) {
          nbciusinvoiceDetailsDOTForms.safety6 = '6'
        } else {
          nbciusinvoiceDetailsDOTForms.safety6 = ''
        }
        if (nbciusinvoiceDetailsDOTForms.safety7 == true) {
          nbciusinvoiceDetailsDOTForms.safety7 = '7'
        } else {
          nbciusinvoiceDetailsDOTForms.safety7 = ''
        }
        if (nbciusinvoiceDetailsDOTForms.safety8 == true) {
          nbciusinvoiceDetailsDOTForms.safety8 = '8'
        } else {
          nbciusinvoiceDetailsDOTForms.safety8 = ''
        }
        if (nbciusinvoiceDetailsDOTForms.safety9 == true) {
          nbciusinvoiceDetailsDOTForms.safety9 = '9'
        } else {
          nbciusinvoiceDetailsDOTForms.safety9 = ''
        }
        if (nbciusinvoiceDetailsDOTForms.safety10 == true) {
          nbciusinvoiceDetailsDOTForms.safety10 = '10'
        } else {
          nbciusinvoiceDetailsDOTForms.safety10 = ''
        }
        if (nbciusinvoiceDetailsDOTForms.safety11 == true) {
          nbciusinvoiceDetailsDOTForms.safety11 = '11'
        } else {
          nbciusinvoiceDetailsDOTForms.safety11 = ''
        }
        if (nbciusinvoiceDetailsDOTForms.safety12 == true) {
          nbciusinvoiceDetailsDOTForms.safety12 = '12'
        } else {
          nbciusinvoiceDetailsDOTForms.safety12 = ''
        }
        if (nbciusinvoiceDetailsDOTForms.safety13 == true) {
          nbciusinvoiceDetailsDOTForms.safety13 = '13'
        } else {
          nbciusinvoiceDetailsDOTForms.safety13 = ''
        }
      }
    })
    console.log(serverData)
  })
  return serverData
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
