import React, {useState} from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import NbciUsInvoiceApiUrls from '../../../apiUrls/NbciUsInvoiceApiUrls'
import FormProperties from './FormProperties'
import InvoiceDetailsTable from './invoiceDetails/InvoiceDetailsTable'
import InvoiceSummarySection from './invoiceSummary/InvoiceSummarySection'
import Grid from '@material-ui/core/Grid'
import PrintFlagLineIteamDialog from '../../../views/nbci/usinvoice/PrintFlagLineIteamDialogAll'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  button: { CngButton },
  form: { CngForm }
} = components

const { FormState } = constants

function InvoiceDetailsAndSummaryInvoiceSection(props) {
  const {
    invoiceData,
    onBack,
    onDiscard,
    // onSetInvoiceData,
    onSetLoading,
    onSkip,
    onSetPrintFlagDialog,
    onSubmit,
    showNotification,
    isPendingSave,
    isPendingSaveShipmentSummary
  } = props

  const { updateRecord } = useServices()
  const [invoiceStateData, setInvoiceStateData] = useState(invoiceData)

  function preSave() {
    if (isPendingSave.current || isPendingSaveShipmentSummary.current) {
      alert("Changes have not been saved. Please save the changes before proceed.")
    } else {
      handleSave(invoiceData.current)
    }
  }

  function handleSave(data) {
    onSetLoading(true)

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    updateRecord.execute(
      NbciUsInvoiceApiUrls.PUT,
      FormProperties.toServerDataFormat(data),
      (response) => {
        showNotification('success', 'Invoice updated.')
        response.invoiceDetails = response.invoiceDetails.map(
          (invoice, index) => ({ ...invoice, _id: index })
        )
        response.invoiceSummary = response.invoiceSummary.map(
          (summary, index) => ({ ...summary, _id: index })
        )
        invoiceData.current = response
      },
      (error) => {
        const { data } = error.response

        if (data && data.errorMessages) {
          showNotification('error', data.errorMessages)
        }
      },
      () => onSetLoading(false)
    )
  }
  const [printFlagDialog, setPrintFlagDialog] = useState({
    open: false,
    manifest: null
  })

  return (
    <>
    <CngForm
      formikProps={{
          initialValues: invoiceData.current,
        makeValidationSchema: FormProperties.formikProps.makeValidationSchema,
          // onSubmit: preSave
      }}
      formState={FormState.COMPLETED}
      innerForm
      renderBodySection={() => (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InvoiceDetailsTable invoiceData={invoiceData} setInvoiceStateData={setInvoiceStateData} />
          </Grid>
          <Grid item xs={12}>
            <InvoiceSummarySection invoiceData={invoiceData} isPendingSave={isPendingSave} isPendingSaveShipmentSummary={isPendingSaveShipmentSummary} invoiceStateData={invoiceStateData} />
          </Grid>
        </Grid>
      )}
      renderButtonSection={() => (
        <ButtonSection
          onBack={onBack}
          onDiscard={onDiscard}
          onSkip={onSkip}
          onSetPrintFlagDialog={() =>
            setPrintFlagDialog({ open: true, manifest: invoiceData.current })
          }
          onSubmit={onSubmit}
          preSave={preSave}
        />
      )}
    />
    <PrintFlagLineIteamDialog
        openDialog={printFlagDialog}
        onClose={() => setPrintFlagDialog({ open: false, manifest: null })}
        showNotification={showNotification}
      />
   </> 
  )
}

function ButtonSection(props) {
  const { onBack, onDiscard, onSkip, onSetPrintFlagDialog, onSubmit, preSave } = props

  return (
    <Grid container justify='space-between' spacing={3}>
      <Grid item xs={12} md='auto'>
        <CngButton color='secondary' onClick={onDiscard} size='medium'>
          Discard
        </CngButton>
      </Grid>
      <Grid item xs={12} md='auto'>
        <Grid container spacing={2}>
          <Grid item xs='auto'>
            <CngButton color='secondary' onClick={onBack} size='medium'>
              Back
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <CngButton color='secondary' onClick={onSkip} size='medium'>
              Skip
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <CngButton color='secondary' onClick={onSetPrintFlagDialog} size='medium'>
              Print Line Item Forms
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <CngButton color='primary' onClick={preSave} size='medium'>
              Save as Draft
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <CngButton color='primary' onClick={onSubmit} size='medium'>
              Submit
            </CngButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default InvoiceDetailsAndSummaryInvoiceSection
