import makeValidationSchema from './CiFormMakeValidationSchema'
import { components, constants, useTranslation } from 'cng-web-lib'
import  React, { useState , useEffect} from 'react'
import Namespace from '../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../constants/locale/key/NbciUsInvoice'
import { Box, Card, Grid, Typography , Divider,InputAdornment,IconButton, useTheme} from '@material-ui/core'
import { NaStateCodeAutoCompleteField } from '../../../components/na'
import CheckboxButton from '../../../components/button/CheckboxButton'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'
import TemplateSelectDialog from '../../../components/aciacehighway/TemplateSelectDialog'
import { NaNbciUsDeclarationAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UsDeclarationsApiUrls from 'src/apiUrls/UsDeclarationsApiUrls'
import moment from 'moment-timezone'
const {
  form: {
    field: {
      CngTextField,
      CngSelectField,
      CngDateField,
      CngDateTimeField,
      CngCountryAutocompleteField,
      CngCodeMasterAutocompleteField,
      CngCheckboxField,
    },
  },
  CngGridItem,
} = components

const {
  filter: { LIKE, EQUAL,IN }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: "",
  partyBilled: "",
  partyBilledOthers: "",
  shipmentDate:  moment().tz("Canada/Eastern").format('YYYY-MM-DD'),
  arrivalDateTime:  moment().tz("Canada/Eastern").format('YYYY-MM-DD HH:mm'),
  markPackLoad: "",
  termOfSale: "",
  reasonForExportation: "",
  transactionParties: "N",
  fbiPurchasePrice: "",
  ffdPurchasePrice: "N",
  bfiPurchasePrice: "",
  dfiPurchasePrice: "",
  goodsSold: "",
  originStateCode: "",
  originCountryCode: "",
  destinationCountryCode: "",
  destinationStateCode: "",
  transportTermsCode: "",
  shipperRegNo: "",
  tscaStatement: "",
  usProForma: "",
  usGoods: "",
  lowValueNafta: "",
  usShipExp: "",
  usGoods10219: "",
  fdaSub: "",
  declNewStmt: "",
  nbciusinvoiceCargoSummary: [],
  saveFlag: false,
  declarationId:"",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap, invHeaderstatus}) {

  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const { getValues, reset, trigger } = useFormContext();
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue, watch } = useFormContext()
  const [templateDialog, setTemplateDialog] = useState(false)

  const partyBilled = watch('ciForm.0.partyBilled')
  const originCountryCode = watch('ciForm.0.originCountryCode')
  const destinationCountryCode = watch('ciForm.0.destinationCountryCode')
  const transactionParties = watch('ciForm.0.transactionParties')
  const ffdPurchasePrice = watch('ciForm.0.ffdPurchasePrice')
  const fbiPurchasePrice = watch('ciForm.0.fbiPurchasePrice')
  const bfiPurchasePrice = watch('ciForm.0.bfiPurchasePrice')
  const dfiPurchasePrice = watch('ciForm.0.dfiPurchasePrice')
  const goodsSold = watch('ciForm.0.goodsSold')
  const tscaStatement = watch('ciForm.0.tscaStatement')
  const usProForma = watch('ciForm.0.usProForma')
  const usGoods = watch('ciForm.0.usGoods')
  const lowValueNafta = watch('ciForm.0.lowValueNafta')
  const usShipExp = watch('ciForm.0.usShipExp')
  const usGoods10219 = watch('ciForm.0.usGoods10219')
  const fdaSub = watch('ciForm.0.fdaSub')
  const markPackLoad = watch('ciForm.0.markPackLoad')
  const saveFlag = watch('saveFlag');
  const renderCiForm = watch('renderCiForm')
  const shipmentDate = watch('shipmentDate');

  const columns = [
    {
      field: 'declarationId',
      title: translatedTextsObject.declarationId
    },
    {
      field: 'declarationDescription',
      title: translatedTextsObject.declarationDescription,
    }
  ]

  const sortOptions = [
    {
      label: translatedTextsObject.declarationId,
      value: 'declarationId'
    }
  ]

  function handleApplyTemplate(template) {
    if (template) {
      const {
        declarationId,
        declarationDescription,
      } = template
    
      setValue('declarationId', declarationId, { shouldDirty: true })
      setValue('ciForm.0.declNewStmt', declarationDescription || '', { shouldDirty: true })

      trigger()
    } else {
      reset()
    }
  }

  function makeTranslatedTextsObject() {
    const ciForm = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.TITLE
    )
    const partyBilled = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.PARTY_BILLED
    )
    const partyBilledOthers = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.PARTY_BILLED_OTHERS
    )
    const shipmentDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.SHIPMENT_DATE
    )
    const arrivalDateTime = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.ARRIVAL_DATE_TIME
    )
    const markPackLoad = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.MARK_PACK_LOAD
    )
    const termOfSale = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.TERM_OF_SALE
    )
    const reasonForExportation = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.REASON_FOR_EXPORTATION
    )
    const transactionParties = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.TRANSACTION_PARTIES
    )
    const fbiPurchasePrice = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.FBI_PURCHASE_PRICE
    )
    const ffdPurchasePrice = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.FFD_PURCHASE_PRICE
    )
    const bfiPurchasePrice = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.BFI_PURCHASE_PRICE
    )
    const dfiPurchasePrice = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.DFI_PURCHASE_PRICE
    )
    const goodsSold = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.GOODS_SOLD
    )
    const originStateCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.ORIGIN_STATE_CODE
    )
    const originCountryCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.ORIGIN_COUNTRY_CODE
    )
    const destinationCountryCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.DESTINATION_COUNTRY_CODE
    )
    const destinationStateCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.DESTINATION_STATE_CODE
    )
    const transportTermsCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.TRANSPORT_TERMS_CODE
    )
    const shipperRegNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.SHIPPER_REG_NO
    )
    const tscaStatement = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.TSCA_STATEMENT
    )
    const usProForma = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.US_PRO_FORMA
    )
    const usGoods = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.US_GOODS
    )
    const lowValueNafta = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.LOW_VALUE_NAFTA
    )
    const usShipExp = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.US_SHIP_EXP
    )
    const usGoods10219 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.US_GOODS10219
    )
    const fdaSub = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.FDA_SUB
    )
    const declNewStmt = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.DECL_NEW_STMT
    )
    const declarationId = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CiForm.DECL_ID
    )
    const saveFlag = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.SAVE_FLAG
    )
    return {
      ciForm,
      partyBilled,
      partyBilledOthers,
      shipmentDate,
      arrivalDateTime,
      markPackLoad,
      termOfSale,
      reasonForExportation,
      originCountryCode,
      originStateCode,
      destinationCountryCode,
      destinationStateCode,
      transportTermsCode,
      shipperRegNo,
      transactionParties,
      fbiPurchasePrice,
      ffdPurchasePrice,
      bfiPurchasePrice,
      dfiPurchasePrice,
      goodsSold,
      tscaStatement,
      usProForma,
      usGoods,
      lowValueNafta,
      usShipExp,
      usGoods10219,
      fdaSub,
      declNewStmt,
      declarationId,
      saveFlag
    }
  }

	return (
    <>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box mb={2}>
          <Typography color='textSecondary'>
            {translatedTextsObject.ciForm}
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap?.partyBilled}>
            <CngCodeMasterAutocompleteField
              key={partyBilled}
                name='ciForm.0.partyBilled'
              label={translatedTextsObject.partyBilled}
              disabled={disabled}
              codeType='US_INV_PARTY_BILLED'
              isRequired
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap?.partyBilledOthers}>
            <CngTextField
                name='ciForm.0.partyBilledOthers'
              label={translatedTextsObject.partyBilledOthers}
              size='small'
              key={renderCiForm}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap?.shipmentDate}>
            <CngDateField
                name='ciForm.0.shipmentDate'
              label={translatedTextsObject.shipmentDate}
              disabled={disabled}
              required={invHeaderstatus!='1002'}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap?.arrivalDateTime}>
            <CngDateTimeField
                name='ciForm.0.arrivalDateTime'
              label={translatedTextsObject.arrivalDateTime}
              format='YYYY-MM-DD HH:mm:ss'
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.markPackLoad}>
            <CngTextField
                name='ciForm.0.markPackLoad'
              label={translatedTextsObject.markPackLoad}
              disabled={disabled}
              size='small'
              key={renderCiForm}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.termOfSale}>
            <CngTextField
                name='ciForm.0.termOfSale'
              label={translatedTextsObject.termOfSale}
              disabled={disabled}
              size='small'
              key={renderCiForm}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.reasonForExportation}>
            <CngTextField
                name='ciForm.0.reasonForExportation'
              label={translatedTextsObject.reasonForExportation}
              disabled={disabled}
              size='small'
              helperText='If goods are not being sold'
              key={renderCiForm}
            />
          </CngGridItem>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box mb={2}>
          <Typography color='textSecondary'>
            FOB related instructions
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap?.originCountryCode}>
            <CngCountryAutocompleteField
                name="ciForm.0.originCountryCode"
              label={translatedTextsObject.originCountryCode}
              disabled={disabled}
              isRequired
              size='small'
			  lookupProps={{
                label: (record) => `${record.code};${record.descriptionEn}`
              }}
              key={renderCiForm}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap?.originStateCode}>
            <NaStateCodeAutoCompleteField
                name="ciForm.0.originStateCode"
              label={translatedTextsObject.originStateCode}
              disabled={disabled}
              countryCode={originCountryCode}
              key={originCountryCode}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap?.destinationCountryCode}>
            <CngCountryAutocompleteField
                name="ciForm.0.destinationCountryCode"
              label={translatedTextsObject.destinationCountryCode}
              disabled={disabled}
              isRequired
              size='small'
			  lookupProps={{
                label: (record) => `${record.code};${record.descriptionEn}`
              }}
              key={renderCiForm}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap?.destinationStateCode}>
            <NaStateCodeAutoCompleteField
                name="ciForm.0.destinationStateCode"
              label={translatedTextsObject.destinationStateCode}
              disabled={disabled}
              countryCode={destinationCountryCode}
              key={destinationCountryCode}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.transportTermsCode}>
            <CngSelectField
                name='ciForm.0.transportTermsCode'
              label={translatedTextsObject.transportTermsCode}
              disabled={disabled}
              items={[
                { text: 'Cost and Freight', value: 'CFR' },
                { text: 'Cost, Insurance, and Freight', value: 'CIF' },
                { text: 'Carriage and Insurance Paid To', value: 'CIP' },
                { text: 'Carriage Paid To', value: 'CPT' },
                { text: 'Delivered at Frontier', value: 'DAF' },
                { text: 'Delivered Duty Paid', value: 'DDP' },
                { text: 'Deliver Duty Unpaid', value: 'DDU' },
                { text: 'Delivered Ex Quay', value: 'DEQ' },
                { text: 'Delivered Ex Ship', value: 'DES' },
                { text: 'Ex Works', value: 'EXW' },
                { text: 'Free Alongside Ship', value: 'FAS' },
                { text: 'Free Carrier', value: 'FCA' },
                { text: 'Free on Board', value: 'FOB' }
              ]}
              isRequired
              size='small'
              key={renderCiForm}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.shipperRegNo}>
            <CngTextField
                name='ciForm.0.shipperRegNo'
              label={translatedTextsObject.shipperRegNo}
              disabled={disabled}
              size='small'
              key={renderCiForm}
            />
          </CngGridItem>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box mb={2}>
          <Typography color='textSecondary'>
            Information disclosure
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.transactionParties}>
            <Card variant='outlined'>
              <Box bgcolor='background.sectionOddBg' p={2}>
                <Box mb={1}>
                  <Typography>
                    {translatedTextsObject.transactionParties}
                  </Typography>
                </Box>
                <CngSelectField
                  label='Answer'
                  disabled={disabled}
                  items={[
                    { text: 'Yes', value: 'Y' },
                    { text: 'No', value: 'N' }
                  ]}
                  isRequired
                  size='small'
                  onChange={(event) => setValue('ciForm.0.ransactionParties', event.target.value)}
                  value={transactionParties ? transactionParties : ''}
                    name='ciForm.0.transactionParties'
                />
              </Box>
            </Card>
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.fbiPurchasePrice}>
            <Card variant='outlined'>
              <Box bgcolor='background.sectionOddBg' p={2}>
                <Box mb={1}>
                  <Typography>
                    {translatedTextsObject.fbiPurchasePrice}
                  </Typography>
                </Box>
                <CngSelectField
                  label='Answer'
                  disabled={disabled}
                  items={[
                    { text: <em>Answer</em>, value: '' },
                    { text: 'Yes', value: 'Y' },
                    { text: 'No', value: 'N' }
                  ]}
                  size='small'
                  onChange={(event) => setValue('ciForm.0.fbiPurchasePrice', event.target.value)}
                  value={fbiPurchasePrice ? fbiPurchasePrice : ''}
                    name='ciForm.0.fbiPurchasePrice'
                />
              </Box>
            </Card>
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.ffdPurchasePrice}>
            <Card variant='outlined'>
              <Box bgcolor='background.sectionOddBg' p={2}>
                <Box mb={1}>
                  <Typography>
                    {translatedTextsObject.ffdPurchasePrice}
                  </Typography>
                </Box>
                <CngSelectField
                  label='Answer'
                  disabled={disabled}
                  items={[
                    { text: 'Yes', value: 'Y' },
                    { text: 'No', value: 'N' }
                  ]}
                  isRequired
                  size='small'
                  onChange={(event) => setValue('ciForm.0.ffdPurchasePrice', event.target.value)}
                  value={ffdPurchasePrice ? ffdPurchasePrice : ''}
                    name='ciForm.0.ffdPurchasePrice'
                />
              </Box>
            </Card>
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.bfiPurchasePrice}>
            <Card variant='outlined'>
              <Box bgcolor='background.sectionOddBg' p={2}>
                <Box mb={1}>
                  <Typography>
                    {translatedTextsObject.bfiPurchasePrice}
                  </Typography>
                </Box>
                <CngSelectField
                  label='Answer'
                  disabled={disabled}
                  items={[
                    { text: <em>Answer</em>, value: '' },
                    { text: 'Yes', value: 'Y' },
                    { text: 'No', value: 'N' }
                  ]}
                  size='small'
                  onChange={(event) => setValue('ciForm.0.bfiPurchasePrice', event.target.value)}
                  value={bfiPurchasePrice ? bfiPurchasePrice : ''}
                    name='ciForm.0.bfiPurchasePrice'
                />
              </Box>
            </Card>
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.dfiPurchasePrice}>
            <Card variant='outlined'>
              <Box bgcolor='background.sectionOddBg' p={2}>
                <Box mb={1}>
                  <Typography>
                    {translatedTextsObject.dfiPurchasePrice}
                  </Typography>
                </Box>
                <CngSelectField
                  label='Answer'
                  disabled={disabled}
                  items={[
                    { text: <em>Answer</em>, value: '' },
                    { text: 'Yes', value: 'Y' },
                    { text: 'No', value: 'N' }
                  ]}
                  size='small'
                  onChange={(event) => setValue('ciForm.0.dfiPurchasePrice', event.target.value)}
                  value={dfiPurchasePrice ? dfiPurchasePrice : ''}
                    name='ciForm.0.dfiPurchasePrice'
                />
              </Box>
            </Card>
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.goodsSold}>
            <Card variant='outlined'>
              <Box bgcolor='background.sectionOddBg' p={2}>
                <Box mb={1}>
                  <Typography>
                    {translatedTextsObject.goodsSold}
                  </Typography>
                </Box>
                <CngSelectField
                  label='Answer'
                  disabled={disabled}
                  items={[
                    { text: <em>Answer</em>, value: '' },
                    { text: 'Yes', value: 'Y' },
                    { text: 'No', value: 'N' }
                  ]}
                  size='small'
                  onChange={(event) => setValue('ciForm.0.goodsSold', event.target.value)}
                  value={goodsSold ? goodsSold : ''}
                    name='ciForm.0.goodsSold'
                />
              </Box>
            </Card>
          </CngGridItem>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box mb={2}>
          <Typography color='textSecondary'>
            Document declaration
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.tscaStatement}>
            <CheckboxButton
                name='ciForm.0.tscaStatement'
              label={translatedTextsObject.tscaStatement}
              checked={tscaStatement === 'Y'}
              disabled={disabled}
              onChange={(event) => setValue('ciForm.0.tscaStatement', event.target.checked ? 'Y' : 'N')}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.usProForma}>
            <CheckboxButton
                name='ciForm.0.usProForma'
              label={translatedTextsObject.usProForma}
              checked={usProForma === 'Y'}
              disabled={disabled}
              onChange={(event) => setValue('ciForm.0.usProForma', event.target.checked ? 'Y' : 'N')}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.usGoods}>
            <CheckboxButton
                name='ciForm.0.usGoods'
              label={translatedTextsObject.usGoods}
              checked={usGoods === 'Y'}
              disabled={disabled}
              onChange={(event) => setValue('ciForm.0.usGoods', event.target.checked ? 'Y' : 'N')}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.lowValueNafta}>
            <CheckboxButton
                name='ciForm.0.lowValueNafta'
              label={translatedTextsObject.lowValueNafta}
              checked={lowValueNafta === 'Y'}
              disabled={disabled}
              onChange={(event) => setValue('ciForm.0.lowValueNafta', event.target.checked ? 'Y' : 'N')}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.usShipExp}>
            <CheckboxButton
                name='ciForm.0.usShipExp'
              label={translatedTextsObject.usShipExp}
              checked={usShipExp === 'Y'}
              disabled={disabled}
              onChange={(event) => setValue('ciForm.0.usShipExp', event.target.checked ? 'Y' : 'N')}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.usGoods10219}>
            <CheckboxButton
                name='ciForm.0.usGoods10219'
              label={translatedTextsObject.usGoods10219}
              checked={usGoods10219 === 'Y'}
              disabled={disabled}
              onChange={(event) => setValue('ciForm.0.usGoods10219', event.target.checked ? 'Y' : 'N')}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.fdaSub}>
            <CheckboxButton
                name='ciForm.0.fdaSub'
              label={translatedTextsObject.fdaSub}
              checked={fdaSub === 'Y'}
              disabled={disabled}
              onChange={(event) => setValue('ciForm.0.fdaSub', event.target.checked ? 'Y' : 'N')}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.saveFlag}>
                  <CngCheckboxField
                    label={
                      <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                        {translatedTextsObject.saveFlag}
                      </Typography>
                    }
                    name='saveFlag'
                    disabled={disabled}
                    onChange={(e) => {
                      setValue('saveFlag', e.target.checked)

                      if (!e.target.checked) {
                        setValue("declarationId", "")
                      }
                    }}
                    size='small'
                    style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                  />
           </CngGridItem>
           <CngGridItem xs={12} sm shouldHide={shouldHideMap?.declarationId}>
                  <CngTextField
                    required
                    name='declarationId'
                    inputProps={{
                      style: { textTransform: 'uppercase' },
                      maxLength: 35
                    }}
                    placeholder={translatedTextsObject.declarationId}
                    disabled={disabled || !saveFlag}
                    onChange={(e) => {
                      setValue("declarationId", e.target.value.toUpperCase(), { shouldValidate: true })
                    }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={4}>
                  <NaNbciUsDeclarationAutoCompleteField
                    name='declDropDown'
                    label='Auto-fill using Declaration'
                    disabled={disabled}
                    onChange={(_, options) => handleApplyTemplate(options.data)}
                    size='small'
                    fullWidth
                    disableClearable
                    textFieldProps={{
                      InputProps: {
                        customEndAdornment: () => (
                          <InputAdornment position='end' style={{ marginTop: -16 }}>
                            <IconButton onClick={(event) => {
                              event.stopPropagation()
                              setTemplateDialog(true)
                            }}>
                              <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                                <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                              </Box>
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    }}
                    forcePopupIcon={false}
                    lookupProps={{
                      filters: [{
                        field: 'partyId',
                        operator: EQUAL,
                        value: FileForUserGetPartyId()
                      }
                    ]
                    }}
                  />
                </CngGridItem>
          <CngGridItem xs={12} shouldHide={shouldHideMap?.declNewStmt}>
            <CngTextField
                name='ciForm.0.declNewStmt'
              label={translatedTextsObject.declNewStmt}
              disabled={disabled}
              size='small'
              key={renderCiForm}
            />
          </CngGridItem>
        </Grid>
      </Grid>
    </Grid>

        <TemplateSelectDialog
        columns={columns}
        defaultSorts={[{ field: 'declarationId', direction: 'ASC' }]}
        fetch={{ url: UsDeclarationsApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        onApplyTemplate={handleApplyTemplate}
        onClose={() => setTemplateDialog(false)}
        open={templateDialog}
        search={{ field: 'declarationId', operator: LIKE }}
        sortOptions={sortOptions}
        title='Template'
        />
        </>
	)
}

const CiFormFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default CiFormFormProperties
