import { Chip, Typography, useTheme } from '@material-ui/core'
import {
  constants,
  useTranslation,
  useServices
} from 'cng-web-lib'

import NbciUsInvoiceApiUrls from '../../../apiUrls/NbciUsInvoiceApiUrls'
import NbciUsInvoiceKeys from '../../../constants/locale/key/NbciUsInvoice'
import Namespace from '../../../constants/locale/Namespace'
import React, { useRef, useState, useEffect } from 'react'
import moment from 'moment'
import Table from '../../../components/aciacehighway/Table'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import pathMap from '../../../paths/pathMap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { getStatusMetadata } from '../../../common/NACommon'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { fetchUser } from "src/views/userprofile/UserProfileService.js";
import sweetalert from 'sweetalert'
import { format } from 'date-fns'

const {
  filter: { BETWEEN, IN, LIKE,EQUAL }
} = constants

function TablePage(props) {
  const { history, location, showNotification } = props
  const tableRef = useRef(null)
  const [alertDialog, setAlertDialog] = useState({ open: false, invoices: null })
  const theme = useTheme()
  const { createRecord, deleteRecord, securedSendRequest } = useServices()
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const [user, setUser] = useState([]);

  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest, fileForUserDetails);
  }, []);

  function makeTranslatedTextsObject() {
    const invoiceNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.INVOICE_NUMBER
    )
    const scacCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.SCAC_CODE
    )
    const invoiceDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.INVOICE_DATE
    )
    const carrierName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CARRIER_NAME
    )
    const entryNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.ENTRY_NUMBER
    )
    const invHeaderstatus = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.STATUS
    )
    const createdDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CREATED_DATE
    )
    const cloneButton = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.CLONE_BUTTON
    )
    const editSentRecords = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.EDIT_SENT_RECORDS
    )
    const editButton = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.EDIT_BUTTON
    )
    const deleteMultipleRecords = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.DELETE_MULTIPLE_RECORDS
    )
    const deleteDraftTemplate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.DELETE_DRAFT_TEMPLATE
    )
    const deleteButton = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.DELETE_BUTTON
    )
    const templateName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TEMPLATE_NAME
    )
    return {
      createdDate,
      invoiceNumber,
      scacCode,
      invoiceDate,
      carrierName,
      entryNumber,
      invHeaderstatus,
      cloneButton,
      editSentRecords,
      editButton,
      deleteMultipleRecords,
      deleteDraftTemplate,
      deleteButton,
      templateName
    }
  }

  const columns = [
    {
      field: 'invoiceNumber',
      title: translatedTextsObject.invoiceNumber
    },
    {
      field: 'scacCode',
      title: translatedTextsObject.scacCode
    },
    {
      field: 'invoiceDate',
      title: translatedTextsObject.invoiceDate,
      render: (rowData) =>
        rowData.invoiceDate && moment(rowData.invoiceDate).format('D MMM YYYY')
    },
    {
      field: 'carrierName',
      title: translatedTextsObject.carrierName
    },
    {
      field: 'entryNumber',
      title: translatedTextsObject.entryNumber
    },
    {
      field: 'createdDate',
      title: translatedTextsObject.createdDate,
      render: (rowData) =>
        rowData.createdDate && moment(rowData.createdDate).format('D MMM YYYY')
    },
    {
      field: 'templateName',
      title: translatedTextsObject.templateName
    },
    {
      field: 'invHeaderstatus',
      title: translatedTextsObject.invHeaderstatus,
      render: (rowData) => {
        if (!rowData.invHeaderstatus) return

        const status = getStatusMetadata(rowData.invHeaderstatus)

        return (
          <Chip
            label={status.label}
            size='small'
            style={{
              color: status.contrastColor,
              backgroundColor: status.color,
              fontSize: 12
            }}
          />
        )
      }
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.invoiceNumber,
      type: 'textfield',
      name: 'invoiceNumber',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.scacCode,
      type: 'textfield',
      name: 'scacCode',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.invoiceDate,
      type: 'daterange',
      name: 'invoiceDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.carrierName,
      type: 'textfield',
      name: 'carrierName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.entryNumber,
      type: 'textfield',
      name: 'entryNumber',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.templateName,
      type: 'textfield',
      name: 'templateName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.invHeaderstatus,
      type: 'checkbox',
      name: 'invHeaderstatus',
      operatorType: IN,
      options: [
        {
          fullWidth: true,
          label: 'Draft',
          value: 'draft',
          filterValue: {
            value: '1005'
          }
        },
        {
          fullWidth: true,
          label: 'Accepted',
          value: 'accepted',
          filterValue: {
            value: '1000'
          }
        },
        {
          fullWidth: true,
          label: 'Sent',
          value: 'sent',
          filterValue: {
            value: '1008'
          }
        },
        {
          fullWidth: true,
          label: 'Rejected',
          value: 'rejected',
          filterValue: {
            value: '1001'
          }
        },
        {
          fullWidth: true,
          label: 'Template',
          value: 'template',
          filterValue: {
            value: '1002'
          }
        }
      ]
    },
    {
      label: translatedTextsObject.createdDate,
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    }
  ]

  function handleCloneInvoice(data) {
    createRecord.execute(
      NbciUsInvoiceApiUrls.CLONE,
      data,
      (data) => {
            const partyData = data.tradeParty.map(
            (tradeParty, index) => ({ ...tradeParty, _id: index + 1 })
          )
          data.tradeParty = partyData
        
        history.push(pathMap.NBCI_US_INVOICE_ADD_VIEW, data)
      },
      (error) => console.log(error)
    )
  }

  function handleDeleteInvoices() {
    if (!_.isEmpty(alertDialog.invoices)) {
      deleteRecord.execute(
        NbciUsInvoiceApiUrls.DELETE,
        alertDialog.invoices,
        () => {
          showNotification('success', 'Delete succeeded')
          setAlertDialog({ open: false, invoices: null })

          if (tableRef.current?.performRefresh) {
            tableRef.current.performRefresh()
          }
        },
        (error) => console.log(error)
      )
    }
  }

  async function exportUSInvoice(data) {
    if (data.length > 0) {
      const config = { responseType: 'blob' }

      securedSendRequest.execute('POST', NbciUsInvoiceApiUrls.EXPORT_CSV, data,
        (response) => {
          let blob = new Blob([response.data])

          let first = 'NBCI_US_INVOICE_EXPORT_'
          let curDate = format(new Date(), 'yyyyMMddHHmmss')
          let filename = first + curDate + '.csv'
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.href = url
          a.download = filename
          a.click()
        },
        (error) => console.error(error),
        undefined,
        config,
        null
      )
    }
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.NBCI_US_INVOICE_ADD_VIEW)
            },
            label: 'Create invoice'
          }
        ]}
        columns={columns}
        compact
        customRowActions={[
          {
            disabled: (rowData) => rowData.invHeaderstatus === '1008',
            icon: ['fal', 'pen'],
            onClick: (rowData) =>
              history.push(`${location.pathname}/edit/${rowData.id}`),
            tooltip: (rowData) =>
              rowData.invHeaderstatus === '1008'
                ? translatedTextsObject.editSentRecords
                : translatedTextsObject.editButton
          }
        ]}
        fetch={{ url: NbciUsInvoiceApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) => history.push(`${location.pathname}/view/${rowData.id}`)}
        rowActions={[
          {
            icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
            label: translatedTextsObject.cloneButton,
            onClick: handleCloneInvoice
          },
          {
            disabled: (rowData) => rowData.invHeaderstatus !== '1005' && rowData.invHeaderstatus !== '1002',
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: translatedTextsObject.deleteButton,
            onClick: (rowData) =>
              setAlertDialog({ open: true, invoices: rowData }),
            tooltip: (rowData) =>
              rowData.invHeaderstatus !== '1005' && rowData.invHeaderstatus !== '1002'
                ? translatedTextsObject.deleteDraftTemplate
                : null
          },
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
        checkboxSelection
        selectActions={[
          {
            icon: <FontAwesomeIcon icon={['fal', 'link']} />,
            label: "Export US Invoice(s)",
            onClick: (rows) => {
              sweetalert("Exporting US Invoice in the background. The report will be downloaded automatically once ready.", "", "success");
              return exportUSInvoice(rows);
            }
          }
        ]}
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={alertDialog.open}
        onClose={() => setAlertDialog({ open: false, invoices: null })}
        onCancel={() => setAlertDialog({ open: false, invoices: null })}
        onConfirm={handleDeleteInvoices}
        title={translatedTextsObject.deleteButton}
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default TablePage
